import { defineMessages } from "@vp/i18n-helper";

export const previewLoadingMessages = defineMessages({
    loadingPreview: {
        id: "studio.components.spinners.loadingPreview",
        defaultMessage: "Loading preview...",
        description: {
            note: "Spinner text shown while preview is loading due to just being opened or due to a change"
        }
    }
});
