import React from "react";
import classNames from "classnames";
import { useIsMailingPostCard } from "@shared/features/MailingServices";
import { usePreviewsContext, StudioPreview, type StudioVortexPreviewProps } from "@shared/features/Previews";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import * as styles from "./ReviewPreviews.module.scss";

const StudioVortexPreview = loadable<
    StudioVortexPreviewProps,
    { StudioVortexPreview: React.ComponentType<StudioVortexPreviewProps> }
>(() => loadableRetry(() => import("@shared/features/Previews")), {
    resolveComponent: components => components.StudioVortexPreview
});

export type ReviewPrevewProps = {
    showPreview: Boolean;
    isReviewPage?: boolean;
    activeCanvasName?: string | undefined;
};

export function ReviewPreviews({ showPreview, isReviewPage = false, activeCanvasName }: ReviewPrevewProps) {
    const isMailingPostCard = useIsMailingPostCard();
    const { previewUrls, previewInstructionsUrl } = usePreviewsContext();
    const previewIndex = isReviewPage ? 0 : previewUrls.findIndex(url => url.title === activeCanvasName);

    if (!showPreview || (!activeCanvasName && !isReviewPage)) {
        return null;
    }

    const controlsClassName = classNames({
        "white-bg": isMailingPostCard && !isReviewPage,
        [styles.whiteBg]: isMailingPostCard && !isReviewPage
    });

    const getFallbackComponent = (initialSelection: number) => {
        return (
            <StudioPreview
                isReviewPage={isReviewPage}
                initialSelection={initialSelection}
                controlsClassName={controlsClassName}
                previewUrls={previewUrls.map(preview => preview.src)}
            />
        );
    };

    return (
        <div className={classNames("review-previews", styles.reviewPreviews)}>
            <StudioVortexPreview
                vortexClassName={classNames("vortex-preview-container", styles.vortexPreviewContainer)}
                controlsClassName={controlsClassName}
                isReviewPage={isReviewPage}
                fallbackComponent={getFallbackComponent}
                previewInstructionsUrl={previewInstructionsUrl}
                previewCount={previewUrls.length}
                previewIndex={previewIndex}
                containerViewHeight={80}
            />
        </div>
    );
}
ReviewPreviews.displayName = "ReviewPreviews";
