import React, { useMemo } from "react";
import {
    Buttonbar,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    ButtonbarButton,
    ButtonbarButtonLabel
} from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import classNames from "classnames";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { usePreviewsContext } from "../PreviewsContext";
import * as styles from "./StudioPreviewControls.module.scss";

type Props = {
    onSelectionChange: (selection: string, event: any) => void;
    initialSelection: number;
    disabled?: boolean;
    className?: string;
    buttonBar?: boolean;
};

const messages = defineMessages({
    reviewPreviewAriaLabel: {
        id: "studio.reviewPreviews.reviewPreviewAriaLabel",
        defaultMessage: "Select a preview",
        description: {
            description: "review preview aria label text"
        }
    },
    selectPreviewAriaLabel: {
        id: "studio.reviewPreviews.selectPreviewAriaLabel",
        defaultMessage: "View [[surfaceLabel]] Preview",
        description: {
            description: "select preview of surfaceLabel"
        }
    }
});

export const StudioPreviewControls = (props: Props) => {
    const { onSelectionChange, initialSelection, disabled, className, buttonBar } = props;
    const { previewUrls } = usePreviewsContext();
    const { t } = useTranslationSSR();
    const designRequirements = useDesignRequirementsContext();

    const labels = useMemo(
        () =>
            previewUrls?.map((previewUrl, index: number) => {
                return previewUrl.title || designRequirements?.panels[index].locationName;
            }),
        [previewUrls, designRequirements]
    );
    if (labels.length < 2) {
        return null;
    }
    return (
        <div className={classNames("review-preview-tools", styles.reviewPreviewTools, className)}>
            {buttonBar ? (
                <Buttonbar
                    variant="single-select"
                    defaultSelectedValue={initialSelection.toString()}
                    onSelectedValueChange={onSelectionChange}
                >
                    {labels?.map((label, index: number) => {
                        return (
                            <ButtonbarButton value={index.toString()} key={label}>
                                <ButtonbarButtonLabel>{label}</ButtonbarButtonLabel>
                            </ButtonbarButton>
                        );
                    })}
                </Buttonbar>
            ) : (
                <div className={classNames("review-preview-toggles", styles.reviewPreviewToggles)}>
                    <SelectionSet
                        variant="single-select"
                        skin="buttons"
                        defaultSelectedValue={initialSelection.toString()}
                        onSelectedValueChange={onSelectionChange}
                        aria-label={t(messages.reviewPreviewAriaLabel.id)}
                    >
                        {labels.map((label, index) => {
                            return (
                                <SelectionSetInput
                                    key={label}
                                    value={index.toString()}
                                    aria-label={t(messages.selectPreviewAriaLabel.id, { surfaceLabel: label })}
                                    disabled={disabled}
                                >
                                    <SelectionSetLabel backgroundColor="white">{label}</SelectionSetLabel>
                                </SelectionSetInput>
                            );
                        })}
                    </SelectionSet>
                </div>
            )}
        </div>
    );
};

StudioPreviewControls.displayName = "StudioPreviewControls";
