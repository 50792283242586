import React from "react";
import { Box, AlertBox } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useAppSelector } from "@shared/redux";
import { MailingIcon } from "@shared/features/StudioChrome";
import { useMailingServicesContext } from "@shared/features/MailingServices";
import * as styles from "./MailingServicePreviewAlert.module.scss";

const messages = defineMessages({
    mailingProductPreviewAlert: {
        id: "studio.components.mailingServices.mailingProductPreviewAlert",
        defaultMessage:
            "The back of the postcard has an area reserved for the address. This is automatically filled out when you add your mailing list later on.",
        description: {
            note: `appears on the preview modal when user previews back side of a mailing postcard.`
        }
    }
});

export function MailingServicePreviewAlert() {
    const { t } = useTranslationSSR();
    const { isMailingPostCard } = useMailingServicesContext();
    const productKey = useAppSelector(state => state.productKey);

    if (!isMailingPostCard(productKey)) {
        return null;
    }
    return (
        <div className={styles.alertContainer}>
            <Box className={styles.alertBox} marginBottom={3}>
                <AlertBox skin="warning" width="narrow" className={styles.alertBoxInfo}>
                    <p className={styles.alertIcon}>
                        <MailingIcon aria-hidden="true" />
                    </p>
                    <p className={styles.alertDescription}>{t(messages.mailingProductPreviewAlert.id)}</p>
                </AlertBox>
            </Box>
        </div>
    );
}

MailingServicePreviewAlert.displayName = "MailingServicePreviewAlert";
