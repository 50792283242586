import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { VortexPreview, VortexContainer, VortexControls } from "@design-stack-vp/react-vortex";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { Typography, FlexBox } from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import { useAppSelector } from "@shared/redux";
import { useIsEmbroidery } from "@shared/features/Embroidery";
import { useAvailablePremiumFinishesForSurfaces } from "@shared/features/PremiumFinish";
import { previewLoadingMessages } from "@shared/utils/Previews";
import classNames from "classnames";
import { useIsVortexEnabled } from "./useIsVortexEnabled";

import * as styles from "./StudioVortexPreview.module.scss";

const messages = defineMessages({
    VortexFrontButtonText: {
        id: "studio.vortexPreview.controls.front",
        defaultMessage: "Front",
        description: {
            note: "Label for button for the vortex preview controls"
        }
    },
    VortexBackButtonText: {
        id: "studio.vortexPreview.controls.back",
        defaultMessage: "Back",
        description: {
            note: "Label for button for the vortex preview controls"
        }
    },
    VortexOpenButtonText: {
        id: "studio.vortexPreview.controls.open",
        defaultMessage: "Open",
        description: {
            note: "Label for button for the vortex preview controls"
        }
    },
    VortexFoldedButtonText: {
        id: "studio.vortexPreview.controls.folded",
        defaultMessage: "Close",
        description: {
            note: "Label for button for the vortex preview controls"
        }
    },
    VortexPreviewLabel: {
        id: "studio.vortexPreview.preview.label",
        defaultMessage: "Vortex Preview",
        description: {
            note: "Aria label for vortex preview"
        }
    }
});

export type StudioVortexPreviewProps = {
    vortexClassName: string;
    controlsClassName?: string;
    showControls?: boolean;
    isReviewPage?: boolean;
    fallbackComponent: (initialSelection: number) => JSX.Element;
    previewInstructionsUrl: string;
    previewIndex: number;
    previewCount: number;
    containerViewHeight?: number;
    forceFallback?: boolean;
    settingsOveride?: Record<string, string>;
};

// This is necessary for the design stack vortex package https://gitlab.com/vistaprint-org/merchandising-technology/configurator/vortex-react/-/blob/master/README.md
const queryClient = new QueryClient();
export const StudioVortexPreview = (props: StudioVortexPreviewProps) => {
    const {
        vortexClassName,
        fallbackComponent,
        controlsClassName,
        showControls = true,
        isReviewPage = false,
        previewInstructionsUrl,
        previewIndex,
        previewCount,
        containerViewHeight = 100,
        forceFallback = false,
        settingsOveride
    } = props;
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const locale = useAppSelector(state => state.locale);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    let attributes = { ...studioSelectedProductOptions };
    if (settingsOveride) {
        attributes = { ...attributes, ...settingsOveride };
    }
    const { auth } = useIdentityContext();
    const authToken = auth.getToken();
    const isVortexEnabled = useIsVortexEnabled() && !forceFallback;
    const { hasPremiumFinishesAvailable } = useAvailablePremiumFinishesForSurfaces();
    const isEmbroidery = useIsEmbroidery();
    const { isSmall } = useStudioLayout();
    const { t } = useTranslationSSR();

    const initialSelection =
        previewCount > 1 && !isVortexEnabled && !isReviewPage && previewIndex > 0 ? previewIndex : 0;

    const canvasHeight =
        !hasPremiumFinishesAvailable && !isEmbroidery && !isSmall
            ? `calc(${containerViewHeight}vh - 180px)`
            : `calc(${containerViewHeight}vh - 100px)`;

    return isVortexEnabled && productVersion !== null ? (
        // Show the vortex preview
        <QueryClientProvider client={queryClient}>
            <VortexContainer className={vortexClassName} fallback={fallbackComponent(initialSelection)}>
                <VortexPreview
                    productKey={productKey}
                    productVersion={productVersion ?? undefined}
                    loader={() => (
                        <FlexBox className={classNames("loader", styles.loader)} flexDirection="column">
                            <LoadingSpinner variant={SpinnerVariant.Large} centering />
                            <Typography fontSize="1">{t(previewLoadingMessages.loadingPreview.id)}</Typography>
                        </FlexBox>
                    )}
                    controlsWrapperProps={{
                        "aria-label": t(messages.VortexPreviewLabel.id),
                        role: "radiogroup"
                    }}
                    completeProductOptionsSet={[
                        {
                            productKey,
                            productVersion,
                            configurations: [{ attributes }]
                        }
                    ]}
                    renderingInstructionsUrl={previewInstructionsUrl}
                    bearerToken={authToken}
                    culture={locale}
                    canvasProps={!isReviewPage ? { height: canvasHeight } : {}}
                    previewOptions={{ renderingOptions: { minimumRenderSize: 2048 } }}
                >
                    {controlsProps => {
                        return (
                            showControls && (
                                <div
                                    className={classNames(
                                        "review-preview-tools",
                                        styles.reviewPreviewTools,
                                        controlsClassName
                                    )}
                                >
                                    <VortexControls
                                        {...controlsProps}
                                        className="vortex-controls"
                                        localizeButtonText={(stateId: string): string =>
                                            t(messages[`Vortex${stateId}ButtonText`].id)
                                        }
                                    />
                                </div>
                            )
                        );
                    }}
                </VortexPreview>
            </VortexContainer>
        </QueryClientProvider>
    ) : (
        <>{fallbackComponent(initialSelection)}</>
    );
};

StudioVortexPreview.displayName = "StudioVortexPreview";
