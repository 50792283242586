import React, { useState } from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "@shared/features/StudioChrome";
import { useIsMailingPostCard, MailingServicePreviewAlert } from "@shared/features/MailingServices";
import { usePreviewsContext } from "@shared/features/Previews";
import { StudioPreviewControls } from "./StudioPreviewControls";
import * as styles from "./StudioPreview.module.scss";

const messages = defineMessages({
    ariaLabelPreviewImage: {
        id: "studio.canvas.ariaLabelPreviewImage",
        defaultMessage: "[[canvasName]] image of [[productName]]",
        description: {
            note: "Appears as a aria label of preview image for studio preview modal"
        }
    }
});

type Props = {
    initialSelection: number;
    controlsClassName?: string;
    isReviewPage?: boolean;
    previewUrls: string[];
    isLoading?: boolean;
    loadingSpinnerComponent?: JSX.Element;
    buttonBarControls?: boolean;
    className?: string;
};

export const StudioPreview = (props: Props) => {
    const {
        initialSelection,
        controlsClassName,
        isReviewPage,
        previewUrls,
        isLoading,
        loadingSpinnerComponent,
        buttonBarControls,
        className
    } = props;
    const isMailingPostCard = useIsMailingPostCard();

    const { previewUrls: previewData, getAltTextForPreview } = usePreviewsContext();
    const [selectedIndex, setSelectedIndex] = useState(previewData[initialSelection] ? initialSelection : 0);
    const { t } = useTranslationSSR();
    const productName = useSelector((state: { productName: string }) => state.productName).toLowerCase();

    const canvasName = previewData[selectedIndex].title;

    return (
        <div className={classNames("studio-preview-container", styles.studioPreviewContainer, className)}>
            {isLoading ? (
                loadingSpinnerComponent || (
                    <div
                        className={classNames(
                            "studio-preview-loading-spinner-container",
                            styles.studioPreviewLoadingSpinnerContainer
                        )}
                    >
                        <LoadingSpinner />
                    </div>
                )
            ) : (
                <>
                    <div
                        className={classNames("review-canvas-image-container", styles.reviewCanvasImageContainer, {
                            "reduced-max-height": isMailingPostCard && !isReviewPage,
                            [styles.reducedMaxHeight]: isMailingPostCard && !isReviewPage
                        })}
                        style={{
                            backgroundImage: previewUrls.length > 0 ? `url(${previewUrls[selectedIndex]})` : ""
                        }}
                        role="img"
                        // In non english locale message is not translated yet, that will take a couple of days, after main code goes to production.
                        aria-label={`${t(messages.ariaLabelPreviewImage.id, {
                            canvasName,
                            productName
                        })} ${getAltTextForPreview(selectedIndex)}`}
                    ></div>
                    {isMailingPostCard && !isReviewPage && (
                        <div style={{ visibility: selectedIndex !== 1 ? "hidden" : undefined }}>
                            <MailingServicePreviewAlert />
                        </div>
                    )}
                    <StudioPreviewControls
                        className={controlsClassName}
                        initialSelection={initialSelection}
                        onSelectionChange={(selection: string) => setSelectedIndex(parseInt(selection, 10))}
                        buttonBar={buttonBarControls}
                    />
                </>
            )}
        </div>
    );
};

StudioPreview.displayName = "StudioPreview";
